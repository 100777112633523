/* General Styling */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}


/* Hero Section Styling */
.hero {
    position: relative;
    background: url('../../images/bg3.jpg') no-repeat center center/cover;
    /* background-color: rgb(15, 15, 15); */
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: antiquewhite;
    overflow: hidden;
}

.hero p{
    z-index: 100;
    color: antiquewhite;
    text-align: center;
    font-size: x-large;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1;
}

.hero .container {
    position: relative;
    z-index: 2;
}

#portfolio {
    position: relative;
    background: url('../../images/workshop2.jpg') no-repeat center center/cover;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: antiquewhite;
}

#portfolio h2 {
    color: antiquewhite;
    font-size: 2.5rem;
}

#portfolio .card {
    background-color: rgba(15, 15, 15, 0.9);
    border: 2px solid white;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: antiquewhite;
}

.card-body  p {
    color: rgb(7, 7, 7);
}

#portfolio .card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 15px rgba(255, 255, 255, 0.2);
}

#portfolio .btn {
    border: 2px solid rgb(7, 7, 7);
    background: none;
    color: rgb(10, 10, 10);
    transition: all 0.3s ease;
}

#portfolio .btn:hover {
    background: rgb(5, 5, 5);
    color: hsl(0, 50%, 99%);
}



/* Profile Image Styling */
.profile-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 3px solid white;
}

/* new card css */
/* Adjusting the card elements */
.custom-card-body {
  background-color: #f8f9fa; /* Light gray background for card body */
  color: black; /* Text color set to black */
  font-size: 0.875rem; /* Reduced font size */
  padding: 1.25rem; /* Slight padding for better spacing */
}

.custom-card-title {
  font-size: 1.25rem; /* Reduced size for the card title */
}

.custom-card-text {
  font-size: 0.875rem; /* Reduced size for the card description text */
}

/* Optional: Reduce font size on smaller screens */
@media (max-width: 768px) {
  .custom-card-title {
    font-size: 1.125rem; /* Slightly smaller title on smaller screens */
  }

  .custom-card-text {
    font-size: 0.8rem; /* Slightly smaller text on smaller screens */
  }
}


/* Footer Styling */
footer {
    background-color: black;
    color: white;
}

footer a {
    color: inherit;
    text-decoration: none;
}
  
  html, body {
    height: 100%;
    margin: 0;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
  }
    

/* Sections */
section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    border-top: 5px solid rgba(255, 255, 255, 0.1);
    color: #080707;
    transition: background 0.5s;
}

/* Hidden Animation */
.hidden {
    opacity: 0;
    transform: translateY(50px) scale(0.9);
    filter: blur(10px);
    transition: all 1.2s cubic-bezier(0.25, 1, 0.5, 1);
}

.show {
    opacity: 1;
    transform: translateY(0) scale(1);
    filter: blur(0);
}

/* Section Text Styling */
section h2 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #020133;
    text-align: center;
    font-size: larger;
}

section p {
    font-size: 1rem;
    line-height: 1.5;
    color: #030303;
    text-align: center;
    font-size: x-large;
    text-align: justify;
}

section .content {
    width: 60vw;
    color: red;
    font-size: x-large;
}

.skill_image{
    width: 7rem;
}

/* Footer */
footer {
    text-align: center;
    padding: 20px;
    /* background-color: #141e30; */
    color: #ffffff;
    font-size: 0.9rem;
}

/* Ensure styles match your About Page pattern */

/* General section styling */
.message-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .message-section .container {
    max-width: 90vw;
    margin: 0 auto;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border: #000000;
  }
  
  .message-section .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .message-section .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .message-section label {
    display: block;
    margin-bottom: 5px;
    font-size: 1rem;
    font-weight: bold;
    color: #555;
  }
  
  .message-section .form-input {
    width: 100%;
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  
  .message-section .form-input:focus {
    border-color: #000000;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  
  .message-section textarea {
    height: 100px;
  }
  
  .message-section .error-text {
    font-size: 0.875rem;
    color: red;
  }
  
  .message-section .btn-submit {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #000000;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .message-section .btn-submit:hover {
    background-color: #555555;
  }
  
  @media (max-width: 768px) {
    .message-section .container {
      padding: 15px;
    }
  
    .message-section .section-title {
      font-size: 1.5rem;
    }
  }

  .contact-info p {
    font-size: medium;
  }
  
  


  @media (max-width: 768px) {
    /* General Styling */
    body {
        font-size: 90%; /* Reduce overall font size */
    }

    /* Hero Section */
    .hero p {
        font-size: large; /* Reduce font size for hero text */
    }

    /* Portfolio Section */
    #portfolio h2 {
        font-size: 2rem; /* Adjust heading size */
    }

    #portfolio .card-body p {
        font-size: small; /* Smaller card body text */
    }

    /* Section Text */
    section h2 {
        font-size: 2rem; /* Adjust section headings */
    }

    section p {
        font-size: 0.9rem; /* Reduce paragraph font size */
    }

    section .content {
        width: 80vw; /* Adjust width for smaller screens */
        font-size: large; /* Slightly reduce content font size */
    }


    /* Footer */
    footer {
        font-size: 0.8rem; /* Smaller footer text */
    }

    /* Message Section */
    .message-section .section-title {
        font-size: 1.5rem; /* Adjust title size */
    }

    .message-section label,
    .message-section .form-input {
        font-size: 0.9rem; /* Adjust input and label font sizes */
    }

    .message-section .btn-submit {
        font-size: 0.9rem; /* Smaller button font size */
        padding: 8px 15px; /* Adjust padding for smaller screens */
    }

    .contact-info p {
        font-size: small; /* Reduce font size for contact info */
    }
}
